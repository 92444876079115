import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA8sOuGVF4u1EVsEBWCNSrho8hqx8apWyw",
  authDomain: "the-real-world-phrase.firebaseapp.com",
  projectId: "the-real-world-phrase",
  storageBucket: "the-real-world-phrase.appspot.com",
  messagingSenderId: "319888871248",
  appId: "1:319888871248:web:2eec77e33b19c53a6a6655",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDtmNsjSnruKS6ZCs3jwqec0QMqGqGKQWk",
//   authDomain: "finance-v2-e1682.firebaseapp.com",
//   projectId: "finance-v2-e1682",
//   storageBucket: "finance-v2-e1682.appspot.com",
//   messagingSenderId: "133386766551",
//   appId: "1:133386766551:web:e0236afa4a4dc881e20892",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
